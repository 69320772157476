@import 'variable';

body {
  .faqPage {
    position: relative;
    z-index: 999;
    touch-action: manipulation;
    a {
      text-decoration: none;
    }
    h3 {
      padding-top: 10px;
      line-height: 28px;
      color: #444444;
    }
    p {
      padding-top: 10px;
    }
    .faqContainer {
      .faqWrapper {
        padding: 2% 9% 5% 9%;
        background-color: $color_white;
        h2 {
          color: $color_3black;
        }

        h3{
          line-height: 21px;
        }
        ul {
          padding-left: 32px;
          li {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            line-height: $lineHeight26;
            color: $color_red;
          }
          li::marker {
            color: $color_red;
            display: inline-block;
          }
        }

        span {
          color: $color_3black;
          display: inline-flex;
        }

        .faqHeader {
          padding-bottom: 17px;
        }
        .faqIcon {
          width: 25px;
          padding: 0 14px 0 0;
        }
        .vrline {
          width: 100%;
          border-top: 1px solid $color_red;
          margin: 45px 0 45px 0;
        }
        .faqDescription {
          p {
            line-height: $lineHeight28;
            color: $color_3black;
          }
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  body {
    .faqPage {
      margin: 70px 13px 15px 13px;

      .faqContainer {
        ul {
          li {
            padding-top: 1px;
            padding-bottom: 1px;
            p {
              padding: 0px;
            }
          }
        }

        h2 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
        h3 {
          padding-top: 16px;
          margin: 0;
          font-size: 14px;
          line-height: 21px;
          color: #333333;
        }
        p {
          font-size: 14px;
          line-height: 21px;
          color: #333333;
          padding-top: 16px;
          padding-bottom: 8px;
        }
        span {
          line-height: 18px;
        }

        .faqWrapper {
          padding: 0;
          border-radius: 10px;
          .faqHeader {
            padding: 4px 0 0px 15px;
          }
          .questionWrapper {
            padding: 20px 16px 20px 16px;
            display: flex;
            align-items: center;
          }
          .vrline {
            width: 100%;
            border-top: 1px solid #f0f0f0;
            margin: 1px 0 1px 0;
          }
        }
      }
    }
  }
}
