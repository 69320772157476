@import 'variable';
body {
  .aboutImgSection {
    touch-action: manipulation;
    background-color: $color_white;
    height: 100%;
  }
  .aboutSection {
    touch-action: manipulation;
    .aboutWrapper {
      background-color: $color_white;
      padding-top: 20px;
      z-index: 999;
      position: relative;
      .imgWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        .imgCover {
          height: 374px;
          border-radius: 20px;
          object-fit: cover;
        }
      }
      .aboutdesc {
        padding: 0 10% 0 10%;
      }
      h1 {
        padding: 40px 0 40px 0;
      }
      h2 {
        padding: 10px 0 5px 0;
        color: $color_black;
      }
      h3 {
        color: $color_3black;
        line-height: $lineHeight28;
      }
      p {
        color: $color_3black;
        line-height: $lineHeight28;
      }
      ul {
        padding-left: 32px;
      }
      li {
        color: $color_red;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        line-height: $lineHeight26;
      }
      span {
        color: $color_3black;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  body {
    .aboutSection {
      margin: 70px 13px 15px 13px;
      .aboutWrapper {
        border-radius: 20px;
        .imgWrapper {
          display: none;
        }
        .aboutdesc {
          padding: 0;
          .MuiGrid-grid-xs-10 {
            flex-grow: 0;
            max-width: 100%;
            flex-basis: 100%;
            .MuiGrid-grid-xs-10 {
              flex-grow: 0;
              max-width: 100%;
              flex-basis: 100%;
            }
          }
        }
        p {
          font-size: 14px;
          line-height: 21px;
          color: #333333;
          width: 100%;
        }
        h3 {
          line-height: 21px;
        }
        h1 {
          padding: 4px 8px 0px 0;
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
          /* identical to box height, or 150% */

          letter-spacing: -0.01em;

          color: #000000;
        }
        h2 {
          padding: 0px 8px 12px 0;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #000000;
        }
      }
    }
  }
}
