@import 'variable';

body {
    

    .contianerPdf {
        max-width: 800px;
    }
    .buttonGroup {
        padding-top: 40px;
        .Btn {
            width: 181px;
            height: 44px;
            border-radius: 100px;
            font-size: $font14;
        }
        .icon {
            padding-right: 20px;
        }
        .btnWrap {
            padding-left: 8px;
            padding-right: 8px;
            .backIcon {
                color: $color_white;
            }
        }
    }

    .pdf {
        touch-action: manipulation;
        background-color: $color_white;
        position: relative;
        z-index: 1000;
        padding-bottom: 3%;

        .heading1 {
            @include fontStyle(
                $fontFamily: $font_Arial,
                $size: $font18,
                $weight: $boldFont,
                $color: $color_black,
                $lineHeight: $lineHeight16
            );
            padding: 4px 8px 4px 8px;
        }

        .para {
            @include fontStyle(
                $fontFamily: $font_Arial,
                $size: $font13,
                $weight: $regularFont,
                $color: $color_black,
                $lineHeight: $lineHeight16
            );
            padding: 4px 8px 4px 8px;
        }

        .label {
            @include fontStyle(
                $fontFamily: $font_Arial,
                $size: $font11,
                $weight: $regularFont,
                $color: #555555,
                $lineHeight: $lineHeight16
            );
            padding: 4px 8px 4px 8px;
        }
        .boldLabel {
            @include fontStyle(
                $fontFamily: $font_Arial,
                $size: $font11,
                $weight: $boldFont,
                $color: $color_black,
                $lineHeight: $lineHeight16
            );
            padding: 4px 8px 4px 8px;
        }

        .sheet {
            touch-action: manipulation;
            width: 100%;
            height: 100%;
            .sheetContainer {
                padding: 7%;
                .leftHeader {
                    position: relative;
                    float: left;
                    text-align: start;
                }
                .rightHeader {
                    position: relative;
                    float: right;
                    text-align: end;
                }
                .table {
                    width: 100%;
                    padding-top: 100px;
                    .divider {
                        border: 0.5px solid #555555;
                        transform: matrix(1, 0, 0, -1, 0, 0);
                    }
                }
            }

            .footer {
                border-top: 1px solid #555555;
                color: $color_white;
                .icon {
                    padding: 21px 0 20px 52px;
                    position: relative;
                    float: left;
                    text-align: start;
                }
                .textWarning {
                    padding: 21px 52px 20px 0;
                    float: right;
                    position: relative;
                    text-align: end;
                }
            }
        }
    }
}

@media (min-width: 0px) and (max-width: 599px) {
    body {
        .contianerPdf {
            button {
                z-index: 1000;
            }
        }
        .page {
            padding-top: 20px;
        }

        .buttonGroup {
            margin-top: 20px;
            .Btn {
                width: 181px;
                height: 44px;
                border-radius: 100px;
                font-size: $font14;
            }
            .icon {
                padding-right: 20px;
            }
            .btnWrap {
                padding-left: 2px;
                padding-right: 2px;
            }
        }
        .pdf {
            min-width: 300px;
            .sheet {
                width: 100%;
                height: 100%;
                .sheetContainer {
                    padding: 6%;
                }
                .athlete {
                    display: none;
                }
                .footer {
                    border-top: 1px solid #555555;
                    color: $color_white;
                    .icon {
                        padding: 13px 0 20px 22px;
                        position: relative;
                        float: left;
                        text-align: start;
                    }
                    .textWarning {
                        display: none;
                    }
                }
            }
        }
    }
}

@media print {
    body {
        .contianerPdf {
            button {
                z-index: 1000;
            }
        }
        .page {
            padding-top: 20px;
        }

        .buttonGroup {
            margin-top: 90px;
            .Btn {
                width: 181px;
                height: 44px;
                border-radius: 100px;
                font-size: $font14;
            }
            .icon {
                padding-right: 20px;
            }
            .btnWrap {
                padding-left: 2px;
                padding-right: 2px;
            }
        }
        .pdf {
            .sheet {
                width: 100%;
                height: 100%;
                .sheetContainer {
                    padding: 7%;
                }
                .athlete {
                    display: inline;
                }
                .footer {
                    border-top: 1px solid #555555;
                    color: $color_white;
                    .icon {
                        padding: 13px 0 20px 22px;
                        position: relative;
                        float: left;
                        text-align: start;
                    }
                    .textWarning {
                        display: inline;
                    }
                }
            }
        }
    }
}
