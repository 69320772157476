@import 'variable';

html {
    font-size: 100%;
}
body {
    .page {
        min-height: 100vh;
        overflow: hidden;
    }
    @include fontStyle(
        $fontFamily: $font_Ubuntu,
        $size: $font14,
        $weight: $regularFont,
        $color: $color_black,
        $lineHeight: $lineHeight24
    );

    * {
        margin: 0;
        padding: 0;
    }
    a {
        text-decoration: none;
        &:hover,
        &:active,
        &:focus,
        &:visited {
            text-decoration: none;
            cursor: pointer;
            outline: 0;
        }
    }

    img {
        max-width: 100%;
    }

    .flexCentered {
        display: flex;
        display: -webkit-flex;
        flex-flow: wrap;
        -webkit-flex-flow: wrap;
        align-items: center;
        &:before,
        &:after {
            width: 0;
        }
    }

    .flexNotCentered {
        display: flex;
        display: -webkit-flex;
        flex-flow: wrap;
        -webkit-flex-flow: wrap;
        &:before,
        &:after {
            width: 0;
        }
    }

    .backgroundProp {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .hidden {
        display: none !important;
    }

    .heading1 {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font36,
            $weight: $boldFont,
            $color: $color_black,
            $lineHeight: $lineHeight41
        );
        letter-spacing: -0.01em;
    }

    .title {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font32,
            $weight: $boldFont,
            $color: $color_black,
            $lineHeight: $lineHeight41
        );
        text-decoration: underline;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .largeHeading {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font40,
            $weight: $lightFont,
            $color: $color_black,
            $lineHeight: $lineHeight41
        );
        letter-spacing: -0.01em;
    }

    .heading2 {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font24,
            $weight: $boldFont,
            $color: $color_black,
            $lineHeight: $lineHeight32
        );
        letter-spacing: -0.04em;
    }

    .heading3 {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font20,
            $weight: $boldFont,
            $color: $color_black,
            $lineHeight: $lineHeight23
        );
    }

    .heading4 {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font18,
            $weight: $boldFont,
            $color: $color_black,
            $lineHeight: $lineHeight24
        );
    }

    .heading5 {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font16,
            $weight: $mediumFont,
            $color: $color_black,
            $lineHeight: $lineHeight22
        );
    }

    .heading6 {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font14,
            $weight: $regularFont,
            $color: $color_black,
            $lineHeight: $lineHeight22
        );
    }

    .paragraph {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font18,
            $weight: $regularFont,
            $color: $color_black,
            $lineHeight: $lineHeight22
        );
    }

    .label {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font12,
            $weight: $lightFont,
            $color: $color_2black,
            $lineHeight: $lineHeight16
        );
    }

    .secondaryText {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font14,
            $weight: $mediumFont,
            $color: $color_black,
            $lineHeight: $lineHeight20
        );
    }

    .supportText {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font12,
            $weight: $mediumFont,
            $color: $color_black,
            $lineHeight: $lineHeight20
        );
    }

    .smallLabel {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font11,
            $weight: $regularFont,
            $color: $color_7black,
            $lineHeight: $lineHeight20
        );
    }

    .errorLabel {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font11,
            $weight: $regularFont,
            $color: $color_red,
            $lineHeight: $lineHeight20
        );
    }

    .navBarFont {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font12,
            $weight: $regularFont,
            $color: #777777,
            $lineHeight: $lineHeight24
        );
    }

    .anchorTag{
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font12,
            $weight: $mediumFont,
            $color:$color_purple,
            $lineHeight: $lineHeight24
        );
    }

    .MuiButton-root {
        min-width: 47px;
    }
    .metaText {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font11,
            $weight: $regularFont,
            $color: $color_black,
            $lineHeight: $lineHeight20
        );
    }

    .primaryBtn {
        @include fontStyle($fontFamily: $font_Ubuntu, $size: $font18, $weight: $regularFont, $color: $color_white);
        background: $color_purple;
        @include transitionForAll($time: 0.4s, $transProp: ease-out);
        border: none;
        cursor: pointer;
        width: 200px;
        height: 65px;
        border-radius: 50px;
        &:hover,
        &:focus,
        &:active {
            background: $color_purple;
            @include transitionForAll($time: 0.3s, $transProp: ease-in);
            outline: none;
        }
        &:disabled {
            border: none;
            pointer-events: none;
            background: #cccccc;
            color: $color_white;
            cursor: not-allowed;
        }
        &.inner {
            padding: 7px 14px;
            font-weight: 500;
        }
        &.create {
            padding: 10px 10px;
            font-weight: 500;
            color: #f8ffe5;
        }
    }

    .secondaryBtn {
        @include fontStyle($fontFamily: $font_Ubuntu, $size: $font18, $weight: $regularFont, $color: $color_white);
        background: $color_lightred;
        @include transitionForAll($time: 0.4s, $transProp: ease-out);
        border: none;
        cursor: pointer;
        width: 200px;
        height: 65px;
        border-radius: 50px;
        &:hover,
        &:focus,
        &:active {
            background: $color_lightred;
            @include transitionForAll($time: 0.3s, $transProp: ease-in);
            outline: none;
        }
        &.disabled {
            border: none;
            pointer-events: none;
            opacity: 0.4;
            cursor: not-allowed;
        }
        &.inner {
            padding: 7px 14px;
            font-weight: 500;
        }
        &.create {
            padding: 10px 10px;
            font-weight: 500;
            color: #f8ffe5;
        }
    }

    .blackBtn {
        @include fontStyle($fontFamily: $font_Ubuntu, $size: $font18, $weight: $regularFont, $color: $color_white);
        background: $color_black;
        @include transitionForAll($time: 0.4s, $transProp: ease-out);
        border: none;
        cursor: pointer;
        width: 120px;
        height: 41px;
        color: $color_white;
        border-radius: 50px;
        &:hover,
        &:focus,
        &:active {
            background: $color_black;
            @include transitionForAll($time: 0.3s, $transProp: ease-in);
            outline: none;
        }
        &:disabled {
            border: none;
            pointer-events: none;
            background: $color_black;
            color: $color_white;
            cursor: not-allowed;
        }
        &.inner {
            padding: 7px 14px;
            font-weight: 500;
        }
        &.create {
            padding: 10px 10px;
            font-weight: 500;
            color: #f8ffe5;
        }
    }

    .listUnstyled {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .link {
        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font14,
            $weight: $mediumFont,
            $color: $color_blue,
            $lineHeight: $lineHeight16
        );
    }

    .listInline {
        list-style: none;
        .listInlineItem {
            display: inline-block;
            width: auto;
            .MuiTouchRipple-root {
                display: none;
            }
        }
    }

    .textCenter {
        text-align: center;
    }
    .textLeft {
        text-align: left;
    }

    .textRight {
        text-align: right;
    }
    .MuiPaper-elevation1 {
        box-shadow: none;
    }
    input,
    textarea,
    select {
        width: 100%;
        height: 40px;

        @include fontStyle(
            $fontFamily: $font_Ubuntu,
            $size: $font14,
            $weight: $mediumFont,
            $color: $color_black,
            $lineHeight: 20px
        );
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #aaaaaa;
        border-radius: 4px;
        padding: 10px 15px;
        box-sizing: border-box;
        &:error {
            border: 1px solid red;
        }
        &:disabled {
            background-color: $color_F8F8F8;
            border: 1px solid $color_EEEEEE;
            box-sizing: border-box;
        }

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            @include fontStyle(
                $fontFamily: $font_Ubuntu,
                $size: $font14,
                $weight: $mediumFont,
                $color: $color_BDBDBD,
                $lineHeight: $lineHeight20
            );
            opacity: 1;
            text-transform: none;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            @include fontStyle(
                $fontFamily: $font_Ubuntu,
                $size: $font14,
                $weight: $mediumFont,
                $color: $color_BDBDBD,
                $lineHeight: $lineHeight20
            );
            text-transform: none;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            @include fontStyle(
                $fontFamily: $font_Ubuntu,
                $size: $font14,
                $weight: $mediumFont,
                $color: $color_BDBDBD,
                $lineHeight: $lineHeight20
            );
            text-transform: none;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            @include fontStyle(
                $fontFamily: $font_Ubuntu,
                $size: $font14,
                $weight: $mediumFont,
                $color: $color_BDBDBD,
                $lineHeight: $lineHeight20
            );
            text-transform: none;
        }
        .MuiTypography-body1 {
            background: none;
            padding-left: 0 !important;
            font-size: $font14;
        }
        &.Mui-disabled {
            border: none;
            background: #ddd;
            opacity: 0.7;
            pointer-events: none;
        }
        &:active,
        &:focus {
            outline: none;
        }
    }

    span {
        .MuiSlider-markLabel {
            top: 45px;
            @include fontStyle(
                $fontFamily: $font_Ubuntu,
                $size: $font10,
                $weight: $mediumFont,
                $color: #999999,
                $lineHeight: $lineHeight14
            );
            text-align: center;
        }
        .PrivateValueLabel-offset-13 {
            @include fontStyle(
                $fontFamily: $font_Ubuntu,
                $size: $font14,
                $weight: $regularFont,
                $color: #999999,
                $lineHeight: $lineHeight14
            );
        }
    }

    .MuiButton-startIcon {
        margin-right: -4px;
    }

    .MuiInput-underline:after {
        border: none;
    }

    input[type='tel']#phone {
        -webkit-appearance: none !important;
        color: $color_black;
        text-align: left;
        border: 1px solid gray;
        border-radius: 0 4px 4px 0;
        border-left: 0px;
        margin: 0 0 0 -70px;
        padding-left: 10px;
        background: white;
        width: 250px;
        &.error {
            border: 1px solid red;
        }
    }
    input[type='text']#phonecode {
        -webkit-appearance: none !important;
        border: 1px solid gray;
        border-right: 0px;
        width: 110px;
        padding-left: 10px;
        outline: none;
    }

    video {
        width: 100%;
        height: auto;
    }

    .ReactCrop .ReactCrop__crop-selection {
        border-radius: 50%;
    }
    .MuiListItemText-root {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    // .MuiListItemIcon-root {
    //     display: inherit;
    // }
    .MuiSelect-selectMenu {
        width: 140px;
    }
    .MuiSelect-outlined.MuiSelect-outlined {
        padding-right: 32px;
        display: inline-flex;
    }
    .MuiListItemIcon-root {
        color: rgba(0, 0, 0, 0.54);
        display: inherit;
        min-width: 26px;
        flex-shrink: 0;
    }

    .MuiOutlinedInput-root {
        position: relative;
        border-radius: 4px;
        height: 45px;
        border: 1px solid #555555;
        background: #eeeeee;
        border-color: #ffffff;
    }

    .MuiGrid-spacing-xs-2 {
         margin: 0;
    }

 
}
