@import 'variable';

@mixin bars($val) {
    transform: rotate(45deg * $val) translate(0, -15px);
    animation-delay: ($val + 1) * 0.4s;
    -webkit-animation-delay: ($val + 1) * 0.4s;
}
body {
    .bar {
        position: relative;
        padding: 30px;
        div {
            height: 9.5px;
            width: 3.5px;
            background: #333;
            position: absolute;
            border-radius: 20px;
            animation: load 15s infinite;
            -webkit-animation: load 15s infinite;
            @for $i from 0 to 10 {
                &:nth-of-type(#{$i}) {
                    @include bars($i);
                }
            }

            @keyframes load {
                0% {
                    background-color: $color_E6E0E0;
                }
                10% {
                    background-color: $color_black;
                }
                20% {
                    background-color: $color_E6E0E0;
                }
                30% {
                    background-color: $color_black;
                }
                40% {
                    background-color: $color_E6E0E0;
                }
                50% {
                    background-color: $color_black;
                }
                60% {
                    background-color: $color_E6E0E0;
                }
                70% {
                    background-color: $color_black;
                }
                80% {
                    background-color: $color_E6E0E0;
                }
                90% {
                    background-color: $color_black;
                }
            }
        }
    }
}
