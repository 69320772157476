@import 'variable';

body {
  .welcomeCard {
    background: $color_white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    touch-action: manipulation;
    .description {
      padding: 121px 0 0 0;
      width: 82%;

      p {
        padding: 20px 0 0 0;
        text-align: center;
        font-size: $font16;
        line-height: 22px;
      }
      h1 {
        text-align: center;
      }
    }
    .clinicDescription {
      text-align: end;
    }

    .paragraph {
      letter-spacing: -0.01em;
      padding-top: 10px;
      pointer-events: none;
    }

    .bottomBtnGroup {
      padding: 90px 38px 29px 24px;

      .startBtnContainer {
        padding-top: 20px;
        text-align: end;
      }
      .clinicIcon {
        height: 65px;
      }
    }

    .assDetail {
      .bold {
        color: $color_purple;
        text-decoration: none;
        display: inline;
        font-weight: $mediumFont;
      }
    }
  }
}

@media (min-width: 0) and (max-width: 599px) {
  body {
    .welcomeCard {
      background: $color_white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      margin-bottom: 13px;
      .description {
        padding: 88px 0 0 0;
        width: 80%;

        p {
          padding: 20px 0 0 0;
          text-align: center;
          pointer-events: none;
          font-size: 13px;
          line-height: 18px;
          letter-spacing: -0.01em;
          color: #000000;
        }
        h1 {
          text-align: center;
          font-size: 28px;
        }
    
      }
      .clinicDescription {
        text-align: center;
      }

      .paragraph {
        letter-spacing: -0.01em;
        padding-top: 10px;
        font-size: 11px;
        line-height: 16px;
        color: #000000;
      }

      .bottomBtnGroup {
        padding: 44px 5% 0 5%;

        text-align: left;

        .startBtnContainer {
          padding-top: 24px;
          padding-bottom: 24px;
          text-align: center;
        }
        .clinicIcon {
          display: none;
        }
      }

      .assDetail {
        background: #f8f8f8;
        padding: 18px;
        .bold {
          display: none;
        }
        p {
          padding: 18px;
        }
      }
    }
  }
}
