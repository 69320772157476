@import "variable";

body {
  .signInCard {
    @include card;
    position: relative;
    touch-action: manipulation;

    .autoComplete {
      margin-left: 40px;
      width: 280px;
    }
    .header {
      background: #f0f2fe;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
      border-radius: 10px 10px 0px 0px;
    }
    input {
      width: 292px;
      margin: 0px 0px 12px 24px;
    }
    h2 {
      padding: 18px 0px 13px 24px;
    }
    p {
      padding: 0px 0px 0px 24px;
      line-height: 27px;
    }
    .btn {
      color: $color_purple;
    }
    .docBtn {
      font-size: 14px;
      width: 205px;
      height: 41px;
    }
    .doctorBtn {
      font-size: 14px;
      width: 205px;
      height: 41px;
    }

    .btnContainer {
      padding-left: 23px;
      padding-top: 17px;
      .circularProgress {
        color: $color_white;
        height: 10px;
        width: 10px;
      }
    }
    .otpInfo {
      padding-top: 27px;
      width: 70%;
    }
    .btmPargraph {
      padding-top: 293px;
      padding-bottom: 24px;
      p {
        font-weight: 400;
      }
    }
    .backButton {
      padding: 7px 0 7px 12px;
      color: $color_purple;
    }
    .icon {
      height: 12px;
    }

    .accountInfo {
      padding-top: 140px;
      padding-bottom: 24px;
      padding-right: 80px;
      p {
        font-size: 16px;
      }
    }
    .resendContainer {
      padding: 10px 0 320px 0;
      .resend {
        color: $color_purple;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  body {
    .signInCard {
      touch-action: manipulation;
      position: relative;
      z-index: 1000;
      margin: 70px 10px 10px 10px;

      .autoComplete {
        margin-left: 40px;
        width: 280px;
      }
      .header {
        background: #f0f2fe;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
        border-radius: 10px 10px 0px 0px;
      }
      input {
        width: 80%;
        margin: 0px 0px 5px 17px;
      }
      .otpContainer {
        input {
          width: 117px;
        }
      }
      h2 {
        padding: 32px 0px 15px 17px;
        font-size: 16px;
        line-height: 18px;
      }

      p {
        padding: 0px 0px 12px 17px;
      }

      .btn {
        color: $color_purple;
      }
      .docBtn {
        width: 230px;
        font-size: 14px;
        height: 55px;
      }
      .doctorBtn {
        width: 170px;
        font-size: 14px;
        height: 55px;
      }

      .btnContainer {
        text-align: left;
        padding-top: 58px;
        padding-bottom: 20px;
        .circularProgress {
          color: $color_white;
          height: 10px;
          width: 10px;
        }
      }
      .docbtnContainer {
        display: flex;
        justify-content: center;
        padding-left: 23px;
        padding-top: 72px;
        button {
        }
      }

      .continuebtnContainer {
        text-align: center;
        padding-bottom: 100px;
        padding-top: 58px;
        .continueBtn {
          width: 170px;
          height: 55px;
        }
      }
      // .otpInfo {
      //     padding-top: 27px;
      //     width: 70%;
      // }
      .btmPargraph {
        padding-top: 183px;
        padding-bottom: 24px;
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          padding: 2px;
          margin: 0 20px 0 20px;
        }
      }
      .backButton {
        padding: 7px 0 7px 12px;
        color: $color_purple;
      }
      .icon {
        height: 12px;
      }

      .accountInfo {
        padding-top: 180px;
      }
      .resendContainer {
        padding: 0 0 0px 0;
        .resend {
          color: $color_purple;
          cursor: pointer;
        }
      }
    }
  }
}
