@import "variable";

body {
  .assessmentCard {
    touch-action: manipulation;
    background: $color_white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: relative;

    .assessmentHeader {
      background: #f0f2fe;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
      height: 58px;
      border-radius: 10px 10px 0px 0px;
      .assessmentWrapper {
        padding: 10px;
        .header {
          text-transform: uppercase;
          font-weight: $boldFont;
        }
        .subheader {
          text-transform: uppercase;
        }

        .headerLeft {
          padding-left: 24px;
        }
        .headerRight {
          padding-right: 24px;
        }
      }
    }

    .assessmentTime {
      touch-action: manipulation;
      height: 100%;
      position: relative;
      .lottieAnim {
        z-index: 99; // lowest in your page
        position: absolute; // or static, depending on what you have
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0.7;
      }
      .otherComp {
        position: relative;
        z-index: 1000;
      }
      .headingClap {
        height: 60px;
      }
      .heading {
        padding: 34px;
      }
      .nextBtn {
        padding: 300px 20px 10px 0;
      }
    }

    .assessmentInfo {
      touch-action: manipulation;
      padding: 0px 30px 0px 30px;
      .heading {
        padding: 7px 0 7px 0;
      }
      .subheading {
        padding-bottom: 12px;
        font-weight: $regularFont;
      }
      .iconButton {
        background: #db7473;
        border-radius: 4px;
        @include fontStyle($font_Ubuntu, $font11, $boldFont, $color_white);
        padding: 1.5px 1px 1.5px 1px;
      }

      .warningIcon {
        color: white;
        width: 14px;
        height: 14px;
        padding-right: 6px;
      }
      button {
        font-family: $font_Ubuntu;
        font-size: 10px;
      }
      .tip {
        @include fontStyle(
          $font_Ubuntu,
          $font12,
          $regularFont,
          $color_2black,
          $lineHeight14
        );
      }
    }

    .assessmentCounter {
      touch-action: manipulation;
      padding: 30px 20px 30px 30px;

      .time {
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        @include fontStyle(
          $font_Ubuntu,
          $font12,
          $regularFont,
          $color_black,
          $lineHeight24
        );
      }
    }
    .assessmentVideo {
      touch-action: manipulation;
      padding: 26px 30px 0px 30px;

      .videoPlayer {
        width: 100%;
        height: 355px;
      }
      .videoWrapper {
        // video::-webkit-media-controls-play-button {
        //     display: none !important;
        // }
        video::-webkit-media-controls-fullscreen-button {
          display: none !important;
        }
        video::-webkit-media-controls-volume-slider {
          display: none !important;
        }
        video::-webkit-media-controls-mute-button {
          display: none !important;
        }
        video::-webkit-media-controls-pause-button {
          display: none !important;
        }
        video::-webkit-media-controls-timeline {
          display: none !important;
        }
        video::-webkit-media-controls-time-remaining-display {
          display: none !important;
        }
        video::-webkit-media-controls-current-time-display {
          display: none !important;
        }
        video::-webkit-media-controls-toggle-closed-captions-button {
          display: none !important;
        }
        // video::-webkit-media-controls-start-playback-button {
        //     display: none !important;
        // }
      }
    }

    .optionContainer {
      touch-action: manipulation;
      position: relative;

      .optionsWrapper {
        position: relative;
        z-index: 1000;
      }
      .lottieAnim {
        z-index: 99; // lowest in your page
        position: absolute; // or static, depending on what you have
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0.7;
      }
      .heading {
        padding: 21px 0 0 34px;
      }
      .question {
        padding: 2px 0 20px 34px;
        font-weight: $lightFont;
      }
      .options {
        padding: 0px 15px 0 30px;
        .optionDesc {
          padding: 0 0 0 5px;
          font-weight: $regularFont;
        }
        .optionPoint {
          text-transform: uppercase;
          font-weight: $boldFont;
          color: $color_black;
        }
        .unchecked {
          background: #eeeeee;
          box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.45);
          width: 43px;
          height: 43px;
          border-radius: 50%;
        }
      }
      .bottomBtnGroup {
        padding: 15px 13px 15px 40px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 0px 0px 10px 10px;

        .time {
          @include fontStyle(
            $font_Ubuntu,
            $font12,
            $mediumFont,
            $color_black,
            $lineHeight22
          );
          letter-spacing: 0.2em;
          text-transform: uppercase;
        }
        .startBtn {
          background: $color_purple;
          border-radius: 50px;
          width: 195px;
          height: 65px;
          letter-spacing: -0.01em;
          text-transform: uppercase;
          @include fontStyle(
            $font_Ubuntu,
            $font18,
            $regularFont,
            $color_white,
            $lineHeight24
          );
        }
      }
    }
    .optionRow {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .optionRow:hover {
      cursor: pointer;
      background-color: #e9f9ff;
    }
  }
  .congratsCard {
    @include card;
    .blueContainer {
      background-color: $color_skyblue;
      border-radius: 10px 10px 0px 0px;

      .congratsImg {
        padding-top: 20px;
        padding-bottom: 10px;
      }
      p {
        color: $color_white;
        text-align: center;
      }
      button {
        width: 290px;
        height: 46px;
      }
      .btnContainer {
        padding: 18px 0 18px 0;
      }
    }
    .whiteContainer {
      .squareBox {
        cursor: pointer;
        border: 1px solid #4a62a3;
        box-sizing: border-box;
        border-radius: 4px;
        width: 211px;
        height: 135px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          display: block;
          color: $color_purple;
        }
      }
      h6 {
        padding: 15px;
      }
      h5 {
        cursor: pointer;
        color: $color_purple;
        padding-bottom: 20px;
      }
      .congratsIcon {
        padding-right: 5px;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  body {
    .userdetails_userDetailCard__3nlTv {
      label {
        padding-left: 0px;
      }
    }
    .assessmentInfo {
      padding: 0px 80px 0px 70px;
      .heading {
        padding: 0px 0 7px 0;
      }
      .subheading {
        padding-bottom: 12px;
        font-weight: $regularFont;
      }
      .iconButton {
        background: #db7473;
        border-radius: 4px;
        @include fontStyle($font_Ubuntu, $font11, $boldFont, $color_white);
        padding: 1.5px 1px 1.5px 1px;
      }

      .warningIcon {
        color: white;
        width: 14px;
        height: 14px;
        padding-right: 6px;
      }
      button {
        font-family: $font_Ubuntu;
        font-size: 10px;
      }
      .tip {
        @include fontStyle(
          $font_Ubuntu,
          $font12,
          $regularFont,
          $color_2black,
          $lineHeight14
        );
      }
    }

    .assessmentCounter {
      padding: 20px;
      .time {
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        padding-left: 5px;
        @include fontStyle(
          $font_Ubuntu,
          $font12,
          $regularFont,
          $color_black,
          $lineHeight24
        );
      }
    }
    .assessmentVideo {
      padding: 12px 20px 0px 40px;
      .videoWrapper {
        height: auto;
        width: 87vw;
        max-width: 900px;

        // video::-webkit-media-controls-play-button {
        //     display: none !important;
        // }
        video::-webkit-media-controls-volume-slider {
          display: none !important;
        }
        video::-webkit-media-controls-mute-button {
          display: none !important;
        }
        video::-webkit-media-controls-timeline {
          display: none !important;
        }
        video::-webkit-media-controls-time-remaining-display {
          display: none !important;
        }
        video::-webkit-media-controls-current-time-display {
          display: none !important;
        }
        video::-webkit-media-controls-toggle-closed-captions-button {
          display: none !important;
        }
        // video::-webkit-media-controls-start-playback-button {
        //     display: none !important;
        //     -webkit-appearance: none;
        // }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  body {
    h3 {
      line-height: 21px;
    }
    .userdetails_userDetailCard__3nlTv {
      label {
        padding-left: 0px;
      }
    }
    .assessmentCard {
      position: relative;
      z-index: 1000;
      margin: 70px 7px 10px 7px;
      .assessmentHeader {
        height: 58px;

        .assessmentWrapper {
          padding: 10px 5px 7px 5px;
          .header {
            padding-top: 5px;
            text-transform: uppercase;
            font-weight: $boldFont;
            font-size: $font14;
            line-height: $lineHeight14;
          }
          .subheader {
            text-transform: uppercase;
          }

          .progressBar {
            // padding-left: 10px;
            padding-right: 10px;
          }

          .headerLeft {
            padding-left: 14px;
          }
          .headerRight {
            padding-right: 14px;
          }
        }
      }

      .options {
        padding: 0px 15px 0 30px;
        .optionDesc {
          padding: 0 0 0 5px;
          margin-left: 10px;
          font-weight: $regularFont;
        }
        .optionPoint {
          text-transform: uppercase;
          font-weight: $boldFont;
          color: $color_black;
        }
        .unchecked {
          background: #eeeeee;
          box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.45);
          width: 43px;
          height: 43px;
          border-radius: 50%;
        }
      }

      .assessmentTime {
        .lottieAnim {
          z-index: 99; // lowest in your page
          position: absolute; // or static, depending on what you have
          width: 100%;
          height: 100%;
          pointer-events: none;
          opacity: 0.7;
        }
        .otherComp {
          position: relative;
          z-index: 1000;
        }
        .heading {
          padding-top: 40%;
          text-align: center;
        }
        .nextBtn {
          padding: 70px 0 30px 0;
          text-align: center;
        }
        .headingClap {
          height: 60px;
        }
      }

      .assessmentInfo {
        padding: 5px 10px 0px 20px;
      }

      .assessmentCounter {
        padding: 50px 20px 20px 20px;
        .time {
          text-align: center;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          padding: 4px 5px 6px 5px;
          line-height: 24px;
          @include fontStyle(
            $font_Ubuntu,
            $font12,
            $regularFont,
            $color_black,
            $lineHeight24
          );
        }
      }
      .assessmentVideo {
        padding: 12px 30px 0px 30px;
        .videoWrapper {
          height: auto;
          width: 87vw;
          max-width: 550px;

          video::-webkit-media-controls-fullscreen-button {
            display: flex !important;
          }
          // video::-webkit-media-controls-play-button {
          //     display: none !important;
          // }
          video::-webkit-media-controls-volume-slider {
            display: none !important;
          }
          video::-webkit-media-controls-mute-button {
            display: none !important;
          }
          video::-webkit-media-controls-timeline {
            display: none !important;
          }
          video::-webkit-media-controls-time-remaining-display {
            display: none !important;
          }
          video::-webkit-media-controls-current-time-display {
            display: none !important;
          }
          video::-webkit-media-controls-toggle-closed-captions-button {
            display: none !important;
          }
          // video::-webkit-media-controls-start-playback-button {
          //     display: none !important;
          //     -webkit-appearance: none;
          // }
        }
      }

      .optionContainer {
        .heading {
          font-size: 22px;
          padding: 32px 0 10px 16px;
        }
        .question {
          padding: 2px 0 10px 16px;
          font-weight: $lightFont;
        }
        .options {
          padding: 0px 20px 0 16px;
          .unchecked {
            background: #eeeeee;
            box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.45);
            width: 26px;
            height: 26px;
            border-radius: 50%;
          }
          .checked {
            width: 26px;
            height: 26px;
          }
        }
        .bottomBtnGroup {
          display: grid;
          justify-content: center;

          padding: 15px 0px 15px 0px;
          .time {
            @include fontStyle(
              $font_Ubuntu,
              $font12,
              $regularFont,
              $color_black,
              $lineHeight24
            );
            letter-spacing: 0.2em;
            text-transform: uppercase;
          }
        }
      }
      .option {
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .optionRow:hover {
        cursor: pointer;
        background-color: #e9f9ff;
      }
    }
    .congratsCard {
      position: absolute;
      z-index: 1000;
      margin: 70px 10px 0 10px;
      .blueContainer {
        background-color: $color_skyblue;
        border-radius: 10px 10px 0px 0px;

        .congratsImg {
          padding-top: 20px;
          padding-bottom: 10px;
        }
        p {
          color: $color_white;
          text-align: center;
        }
        button {
          width: 290px;
          height: 46px;
        }
        .btnContainer {
          padding: 18px 0 18px 0;
        }
      }
      .whiteContainer {
        .squareBox {
          border: none;
          border-radius: 4px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .congratsIcon {
          color: $color_purple;
          margin-top: 10px;
          img {
            width: 50px;
            border-radius: 50%;
          }
        }
        p {
          color: $color_purple;
          font-weight: $regularFont;
        }
        h6 {
          padding: 15px;
        }
        h5 {
          cursor: pointer;
          color: $color_purple;
          padding-top: 20px;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 365px) {
  body {
    .assessmentCard {
      .assessmentHeader {
        .assessmentWrapper {
          .header {
            font-size: $font12;
          }
          .subheader {
            font-size: 8px;
          }
        }
      }
    }
  }
}
