@import "variable";

@media (min-width: 0px) and (max-width: 599px) {
  body {
    .phoneInput {
      margin: 0px 17px 10px 17px;
    }

    .MuiGrid-container {
      width: -webkit-fill-available;
    }
    input[type="tel"]#phone {
      border: 1px solid #aaaaaa;
    }
    input[type="text"]#phonecode {
      border: 1px solid #aaaaaa;
      background: $color_white;
    }

    .userdetails_userDetailCard__3nlTv {
      label {
        padding-left: 0px;
      }
    }
    .refer {
      padding: 0;
      display: grid;
      justify-content: center;
      text-align: center;
      a {
        color: inherit;
        text-decoration: underline;
        font-size: 13px;
      }
    }
    .userDetailCard {
      .MuiContainer-root {
        padding: 0px 5px 0px 5px;
      }
    }
    .userScoreTab {
      margin: 0 7px 0 7px;
    }
    .userImageEditContainer {
      margin-left: 20px;
      margin-top: 8px;
      input {
        width: 100%;
      }
    }

    .sectionContainerSecond {
      padding: 0px;
      width: 100%;
      position: relative;
      z-index: 999;
    }
    .heading1 {
      font-size: $font24;
    }

    .heading2 {
      font-size: $font18;
      line-height: $lineHeight24;
    }
    .heading3 {
      font-size: $font18;
      line-height: $lineHeight21;
    }

    .heading5 {
      font-size: $font14;
    }

    .heading6 {
      font-size: $font12;
      line-height: $lineHeight14;
    }

    .paragraph {
      font-size: $font14;
    }

    .secondaryText {
      @include fontStyle(
        $fontFamily: $font_Ubuntu,
        $size: $font12,
        $weight: $regularFont,
        $color: $color_black,
        $lineHeight: $lineHeight20
      );
    }

    .title {
      @include fontStyle(
        $fontFamily: $font_Ubuntu,
        $size: $font20,
        $weight: $boldFont,
        $color: $color_black,
        $lineHeight: $lineHeight41
      );
      text-decoration: underline;
      padding: 15px;
    }
    .titleLink {
      @include fontStyle(
        $fontFamily: $font_Ubuntu,
        $size: $font20,
        $weight: $mediumFont,
        $color: $color_black,
        $lineHeight: 60px
      );
    }

    .smallLabel {
      font-size: $font10;
    }

    .primaryBtn {
      font-size: 14px;
      width: 170px;
      height: 55px;
    }

    .secondaryBtn {
      font-size: 14px;
      width: 170px;
      height: 55px;
    }

    .blackBtn {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      width: 170px;
      height: 55px;
    }
    .policy {
      display: none;
    }
    .copyright {
      display: none;
    }

    .faqcard {
      height: 100%;
      .faqheader {
        padding: 17px 10px 20px 13px;
      }
      p {
        line-height: 18px;
      }
    }

    .aboutContainer {
      height: 100%;
      .aboutHeader {
        padding: 17px 10px 20px 13px;
        h2 {
          line-height: $lineHeight18;
          padding-bottom: 9px;
        }
      }
    }

    .serverDown {
      background: $color_white;
      height: 50vh;
      padding-top: 50%;
      position: relative;
      border-radius: 0px;
      z-index: 1000;
      box-shadow: none;
      .content {
        width: 80%;
      }
    }

    .sectionPage {
      .sectionContainer {
        margin-top: 72px;
        position: relative;
        z-index: 999;
      }
      .wrongPageContainer {
        background: $color_white;
        display: absolute;
        z-index: 99999;
        height: 100%;
        width: 100%;
        margin: 0px;
      }
    }
    .makeStyles-paper-28 {
      p {
        padding: 4px 0 20px 0;
      }
      button {
        width: 232px;
      }
    }

    .makeStyles-paper-12 {
      p {
        padding: 4px 50px 20px 50px;
      }
      button {
        width: 190px;
      }
    }

    #score {
      height: 50vh;
      display: grid;
      place-items: center;
      position: relative;
      z-index: 99;
      box-shadow: "none";
      border-radius: 0 0 10px 10px;
    }

    .commonCard {
      height: 100vh;
      display: grid;
      place-items: center;
      position: relative;
      z-index: 99;
    }

    input[type="tel"]#phone {
      -webkit-appearance: none !important;
      color: $color_black;
      text-align: left;
      border: 1px solid gray;
      border-radius: 0 4px 4px 0;
      border-left: 0px;
      margin: 0 0 0 -70px;
      padding-left: 7px;
      background: white;
      width: 220px;
      height: 40px;
    }
    input[type="text"]#phonecode {
      -webkit-appearance: none !important;
      border: 1px solid gray;
      border-right: 0px;
      width: 110px;
      padding-left: 9px;
      outline: none;
      height: 40px;
    }

    .MuiSelect-selectMenu {
      width: 65vw;
    }
    .MuiSelect-outlined.MuiSelect-outlined {
      padding-right: 32px;
      display: inline-flex;
    }

    .MuiTableCell-sizeSmall {
      padding: 6px 24px 6px 1px;
    }

    .MuiAutocomplete-popper {
      padding-top: 0px;
      padding-left: 15px;
      width: 70%;
    }
    .autocomplete {
      width: 80%;
    }

    .MuiContainer-root {
      padding-left: 13px;
      padding-right: 13px;
    }

    .MuiGrid-spacing-xs-1 > .MuiGrid-item {
      padding: 5px;
    }

    .navBar {
      position: fixed;
      width: 100%;
      height: 60px;
      left: 0px;
      z-index: 9999;
      top: 0px;
      background: #ffffff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

      button {
        font-size: 11px;
        width: 88px;
        height: 30px;
      }
      .mobileNavContainer {
        position: relative;
        .paragraph {
          padding: 17px;
        }
        .timer {
          padding-top: 15px;
        }

        .logo {
          height: 20px;
          box-shadow: 0 0 0 0;
          margin-top: 20px;
          padding-left: 15px;
        }
        .imgAvatar {
          width: 24px;
          margin-top: 20px;
          box-shadow: 0 0 0 0;
          border-radius: 50%;
        }
        .menuIcon {
          padding: 15px 10px 0px 10px;
          color: $color_purple;
        }
        .profileIcon {
          padding-top: 20px;
          width: 24px;
        }

        .vrline {
          border: 1px solid #dddddd;
          width: 100%;
          height: 2px;
        }
        h4 {
          padding: 20px 0 20px 0;
        }
        p {
          font-weight: 500;
          color: $color_purple;
          padding-left: 4px;
        }
        h5 {
          line-height: 15px;
          padding-top: 4px;
          padding-right: 5px;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 281px) {
  body {
    .faqcard {
      height: 100%;
    }
  }
}
