$font_Ubuntu: 'Ubuntu', sans-serif;
$font_Arial: 'Arial', sans-serif;

$lightFont: 300;
$regularFont: 400;
$mediumFont: 500;
$semiBoldFont: 600;
$boldFont: 700;

$color_black: #011627;
$color_2black: #555555;
$color_3black: #444444;
$color_4black: #333333;
$color_7black: #777777;
$color_white: #ffffff;
$color_yellow: #fadd63;
$color_green: #00d2ad;
$color_header: #f0f2fe;
$color_red: #db7473;
$color_lightred: #ed5757;
$color_darkred: #c91c1c;
$color_purple: #4a62a3;
$color_grey: #b6b6b6;
$color_skyblue: #61bce1;
$color_blue: #2f80ed;

$color_BDBDBD: #bdbdbd;
$color_E6E0E0: #e6e0e0;
$color_778DA9: #778da9;
$color_011627: #011627;
$color_828282: #828282;
$color_AAAAAA: #aaaaaa;
$color_003844: #003844;
$color_F8FFE5: #f8ffe5;
$color_F8F8F8: #f8f8f8;
$color_00D2AD: #00d2ad;
$color_EEEEEE: #eeeeee;

// $font52: 52px;
$font10: 10px;
$font24: 24px;
$font20: 20px;
$font32: 32px;
$font36: 36px;
$font40: 40px;
$font18: 18px;
$font16: 16px;
$font14: 14px;
$font13: 13px;
$font12: 12px;
$font11: 11px;
$font8: 8px;

$lineHeight48: 48px;
$lineHeight41: 41px;
$lineHeight36: 36px;
$lineHeight32: 32px;
$lineHeight30: 30px;
$lineHeight28: 28px;
$lineHeight27: 27px;
$lineHeight26: 26px;
$lineHeight24: 24px;
$lineHeight23: 23px;
$lineHeight22: 22px;
$lineHeight21: 21px;
$lineHeight20: 20px;
$lineHeight18: 18px;
$lineHeight16: 16px;
$lineHeight14: 14px;
$lineHeight15: 15px;
$lineHeight13: 13px;

@mixin fontStyle(
    $fontFamily: null,
    $size: null,
    $weight: null,
    $color: null,
    $lineHeight: null,
    $margin: null,
    $padding: null
) {
    @if $fontFamily != null {
        font-family: $fontFamily;
    }
    @if $size != null {
        font-size: $size;
    }
    @if $weight != null {
        font-weight: $weight;
    }
    @if $color != null {
        color: $color;
    }
    @if $lineHeight != null {
        line-height: $lineHeight;
    }
    @if $margin != null {
        margin: $margin;
    }
    @if $padding != null {
        padding: $padding;
    }
}

@mixin transitionForAll($time: 0.3s, $transProp: ease) {
    -webkit-transition: all $time $transProp;
    -moz-transition: all $time $transProp;
    -o-transition: all $time $transProp;
    transition: all $time $transProp;
    transition: all $time $transProp;
}

@mixin card {
    background: $color_white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}
