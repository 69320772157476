@media (min-width: 600px) and (max-width: 959px) {
    body {
        .faqcard {
            min-height: 227px;
            .faqheader {
                padding: 17px 10px 22px 13px;
            }
            .arrowBtnGroup{
                display: none;
            }
            .faqicon{
                display: none;
            }
        }
     

        .aboutContainer {
            min-height: 227px;
            .heading6{
                font-size:14px;
            }
            .aboutHeader {
                padding: 17px 10px 20px 13px;
            }
            .bottomGrp {
               display: none;
             
              }
        }
        @media (min-width: 600px) and (max-width: 670px){
            .faqcard {
                .arrowBtnGroup{
                    padding-top:0px;
                }
                .aboutContainer {
                    .bottomGrp{
                        padding-top: 100px;
                    }
                }
               
            }
        }
        @media (min-width: 600px) and (max-width: 638px){
            .aboutContainer {
                .bottomGrp{
                    padding-top: 80px;
                }
            }
        }
    }
}
