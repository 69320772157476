@import 'variable';

body {
  background-color: $color_red;
  overflow-x: hidden;

  .disableCard {
    pointer-events: none;
    opacity: 0.4;
  }

  .hidden {
    display: none;
  }

  .MuiSlider-mark {
    display: none;
  }

  .refer {
    padding: 0;
    display: grid;
    justify-content: center;
    text-align: center;
    a {
      color: inherit;
      text-decoration: underline;
      font-size: 16px;
    }
  }

  .phoneInput {
    margin: 17px 23px 10px 23px;
  }

  .anchor {
    text-decoration: none;
    color: $color_black;
  }
  .anchorBlue {
    text-decoration: none;
    color: $color_purple;
  }
  .sectionContainerSecond {
    padding: 50px 10px 0px 10px;
    position: relative;
    z-index: 999;
  }
  .gray {
    color: $color_3black;
  }

  .sectionPage {
    .sectionContainer {
      margin-top: 80px;
      position: relative;
      z-index: 999;
    }
    .wrongPageContainer {
      margin-top: 80px;
      position: relative;
      z-index: 999;
    }
  }
  .faqcard {
    height: 207px;
    background: $color_white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    .link {
      cursor: point;
    }

    h1: {
      display: block;
      width: 80;
    }
    .faqheader {
      padding: 22px 22px 3px 22px;
    }
    .arrowBtnGroup {
      margin: 75px 0 0px 0;
      .arrowIcon {
        width: 11px;
      }
    }
    .faqicon {
      text-align: end;
      width: 42px;
    }
  }

  .aboutContainer {
    height: 207px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.56)),
      url('https://firebasestorage.googleapis.com/v0/b/project---myopal.appspot.com/o/site%2Fassets%2Fchildren.jpg?alt=media&token=1712d868-c688-4dff-85a9-d90dae3a6600');
    .aboutHeader {
      padding: 22px 22px 87.5px 22px;
    }
    .aboutHeader:hover {
      padding: 22px 22px 18px 22px;
      h4 {
        opacity: 1;
        height: auto;
        padding-top: 1px;
        font-size: 14px;
        line-height: 20px;
      }
      h3 {
        display: none;
      }
    }

    .heading2 {
      color: $color_white;
    }
    h4 {
      color: $color_white;
      transition: opacity 1.5s ease-out;
      opacity: 0;
      height: 0;
      font-size: 14px;
      overflow: hidden;
      line-height: 20px;
    }
    h3 {
      color: $color_white;
    }
    .bottomGrp {
      position: absolute;
      top: 168px;
      .btnMore {
        margin: 5px 0 14px 22px;
        color: $color_white;
      }
      .arrowIcon {
        width: 13px;
        color: $color_white;
      }
    }
  }

  .policy {
    @include fontStyle($font_Ubuntu, $font11, $regularFont, $color_white);
    display: inline;
  }
  .copyright {
    opacity: 0.6;
    @include fontStyle($font_Ubuntu, $font11, $regularFont, $color_white, 15px);
    .anchor {
      text-decoration: none;
      color: $color_white;
    }
  }
  .leftBcg {
    position: fixed;
    z-index: 2;
    left: -190px;
    top: 170px;
  }
  .rightBcg {
    position: fixed;
    right: -195px;
    z-index: 2;
    top: 150px;
  }
  .dialogBtn {
    width: 200px;
    height: 65px;
    margin: 10px;
  }
  .exitBtn {
    width: 338px;
    height: 65px;
    margin: 10px;
  }
  .navBar {
    position: fixed;
    width: 100%;
    left: 0px;
    z-index: 9999;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

    .navContainer {
      padding: 7px 0 0 0;
      .userIcon {
        height: 36px;
        margin-top: 4px;
      }
      .logo {
        height: 27px;
        box-shadow: 0 0 0 0;
        padding: 13px 0 10px 0;
      }
      .imgAvatar {
        height: 42px;
        margin-top: 5px;
        box-shadow: 0 0 0 0;
        border-radius: 50%;
      }
      .smallLabel {
        font-size: 12px;
      }
      p {
        font-weight: 500;
        color: $color_purple;
        padding: 0;
        font-size: 14px;
      }
      h5 {
        line-height: 15px;
        padding: 2px;
      }
    }
  }

  .calendar {
    @include fontStyle($font_Ubuntu, $font36, $boldFont, $color_black, $lineHeight16);
    padding: 55% 0 0 0;
  }

  .block {
    padding: 20px 0 20px 0;
  }
  .blockquote {
    @include fontStyle($font_Ubuntu, $font32, $lightFont, $color_black, $lineHeight48);
    font-style: italic;
    padding: 10px 0 10px 0;
  }

  .hrline {
    border-left: 1px solid #b7b7b7;
    height: 100%;
  }

  .commonCard {
    height: 60vh;
    display: grid;
    place-items: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .router {
    text-decoration: none;
    color: none;
  }

  .serverDown {
    background: $color_white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding-top: 30%;
    padding-bottom: 30%;
    .content {
      width: 60%;
      text-align: center;
    }
    img {
      width: 159px;
    }
    .buttonCtr {
      padding-top: 10px;
    }
    h2 {
      padding: 16px 0 9px 0;
    }
    p {
      padding-bottom: 16px;
    }
    button {
      background-color: $color_black;
    }
  }

  .disableLink {
    pointer-events: none;
  }

  .MuiMenuItem-root {
    padding-left: 74px;
    @include fontStyle($font_Ubuntu, $font12, $regularFont, $color_purple, $lineHeight16);
  }

  .MuiAutocomplete-popper {
    padding-top: 0px;
    padding-left: 35px;
    width: 80%;
  }

  .autocomplete {
    width: 100%;
  }
  .makeStyles-paper-12 {
    p {
      padding: 4px 50px 20px 50px;
    }
    button {
      width: 270px;
    }
  }
}
