@import "variable";
body {
  .userInitialDetails {
    @include card;

    h2 {
      padding: 34px 34px 0 34px;
    }

    p {
      margin-bottom: 5px;
      font-weight: 500;
    }
    .submitBtn {
      justify-content: flex-end;
      display: flex;
      padding: 41px 28px 28px 28px;
    }
    .userName {
      padding: 32px 34px 34px 32px;
    }
    .slider {
      padding: 0px 34px 34px 34px;
      .customSlider {
        padding: 32px 10px 10px 20px;
        display: block;
      }
    }

    .userGender {
      padding: 0 0 47px 34px;
      .radioGrp {
        padding-left: 10px;

        .radioButtonForm {
          background: #ffffff;
          border: 1px solid #aaaaaa;
          box-sizing: border-box;
          border-radius: 4px;
          background-color: $color_white;
          margin-right: 25px;
          width: 118px;
          .icons {
            padding-right: 15px;
            padding-top: 3px;
            display: flex;
            margin-top: 5px;
            color: inherit;
            svg {
              padding: 2px 4px 2px 0;
              color: inherit;
            }

            .labelIcon {
              font-weight: 400;
              color: inherit;
              font-size: 14px;
              margin-top: 4px;
            }
          }
        }
        .radioButtonFormClicked {
          background: #ffffff;
          border: 1px solid #aaaaaa;
          box-sizing: border-box;
          border-radius: 4px;
          height: 49px;
          width: 118px;
          margin-right: 25px;
          background-color: #4a62a3;
          color: $color_white;
          .icons {
            padding-right: 15px;
            margin-top: 5px;
            display: flex;
            color: inherit;
            svg {
              padding-right: 4px;
              color: inherit;
            }

            .labelIcon {
              font-weight: 400;
              color: inherit;
              font-size: 14px;
              margin-top: 3px;
            }
          }
        }
        .radioBtn {
          color: #4a62a3;

          .checkedIcon {
            width: 21px;
          }
          .radioEmpty {
            width: 21px;
            height: 21px;
            background: #eeeeee;
            border-radius: 50px;
            box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
  }
  .userDetailCard {
    margin-top: 30px;
    background-color: $color_white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 1000;
    position: relative;
    .paper {
      .para {
        padding: 6px 0 19px 0;
        font-weight: $lightFont;
        font-size: 18px;
      }
      button {
        width: 250px;
      }
    }

    input {
      width: 185px;
    }
    p {
      cursor: pointer;
    }

    .dateContainer {
      padding-left: 20px;
      input {
        width: 120px;
      }
    }
    .userInput {
      padding: 43px 10px 0 10px;
      .textLabel {
        padding-left: 8px;
      }
    }
    .avatar {
      width: 130px;
      height: 130px;
      border-radius: 50%;
    }
    .inputContainer {
      padding-left: 18px;
    }

    .userImageContainer {
      label {
        padding-left: 0px;
      }
      .circle {
        width: 130px;
        height: 130px;
        background: #ffffff;
        border: 1px solid #aaaaaa;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        cursor: pointer;
        border-radius: 50%;
        color: $color_purple;
        justify-content: center;
        align-items: center;
      }
    }
    .picContainer {
      width: 130px;

      .changeProfilePic {
        margin-top: 65px;
        height: 65px;
        width: 130px;
        border-radius: 0 0 90px 90px;
        color: $color_white;
        background: #000000;
        opacity: 0.75;
        position: absolute;
        text-align: center;
        p {
          color: $color_white;

          width: 50%;
          padding: 5% 25% 0 25%;
        }
      }
      img {
        opacity: 1;
      }
    }

    .iconButton {
      padding: 0px;
      margin: 0px;
    }
    .closeIcon {
      width: 30px;
    }
    .edit {
      text-transform: uppercase;
      padding: 11px 19px 0 0;
      color: $color_purple;
    }
    .memberDetail {
      padding: 5px 0 5px 0;
      color: $color_4black;
    }
    .userInfoContainer {
      padding-top: 0px;
    }

    .bottomBtnGroup {
      .hrline {
        width: 99%;
        border: 1px solid #dddddd;
      }
      .updateBtn {
        padding: 20px;
      }
      .blackBtnSpace {
        padding: 40px;
      }
      .datadelete {
        text-transform: uppercase;
        padding: 10px;
        cursor: pointer;
        color: $color_darkred;
      }
    }
  }

  .userScoreCard {
    margin-top: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 1000;

    position: relative;
    .userScoreHeader {
      background: #f0f2fe;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
      height: 50px;
      border-radius: 10px 10px 0px 0px;
      .dateContainer {
        text-align: end;
        .date {
          text-transform: uppercase;
          padding: 18px 29px 0 0;
        }
      }

      .userType {
        padding: 18px 0 0 29px;
      }
    }
    .userScoreTable {
      .coachMarker {
        width: 300px;
        padding: 100px 0 100px 100px;
      }
      .tableIcons {
        padding-top: 5px;
        width: 34px;
      }
      .paginationContainer {
        padding-bottom: 20px;
        .pagination {
          padding: 8px 0 8px 0;
          font-family: "Ubuntu Mono", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 18px;
          color: #636363;
        }
        td {
          padding: 0px;
        }
      }
    }

    .header {
      padding: 22px 0 0 30px;
    }
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  body {
    .textLabel {
      label {
        padding-left: 15px;
      }
    }

    input {
      width: 100%;
    }

    .userInitialDetails {
      .slider {
        padding: 0px 34px 4px 34px;
        .customSlider {
          padding: 28px 5px 10px 34px;
          display: block;
        }
      }

      .submitBtn {
        padding-top: 100px;
        justify-content: center;
        display: flex;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 360px) {
  body {
    .userDetailCard {
      margin-bottom: 13px;
      .textLabel {
        label {
          padding-left: 5px;
          font-size: 12px;
        }
      }

      .inputContainer {
        padding-left: 10px;
      }
      .dateContainer {
        padding-left: 10px;
        input {
          width: 90%;
        }
      }
      .bottomBtnGroup {
        .fixedWrapper {
          width: 92.5vw;
        }
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 400px) {
  body {
    .userDetailCard {
      margin-bottom: 13px;
      .textLabel {
        label {
          padding-left: 5px;
          font-size: 12px;
        }
      }

      .inputContainer {
        padding-left: 10px;
      }
      .dateContainer {
        padding-left: 10px;
        input {
          width: 90%;
        }
      }
      .bottomBtnGroup {
        .fixedWrapper {
          width: 93.4vw;
        }
      }
    }
  }
}

@media (min-width: 400px) and (max-width: 460px) {
  body {
    .userDetailCard {
      .textLabel {
        label {
          padding-left: 5px;
          font-size: 12px;
        }
      }

      .inputContainer {
        padding-left: 10px;
      }
      .dateContainer {
        padding-left: 10px;
        input {
          width: 90%;
        }
      }
      .bottomBtnGroup {
        .fixedWrapper {
          width: 93.8vw;
        }
      }
    }
  }
}
@media (min-width: 460px) and (max-width: 520px) {
  body {
    .userDetailCard {
      .textLabel {
        label {
          padding-left: 20px;
          font-size: 12px;
        }
      }

      .inputContainer {
        padding-left: 20px;
      }
      .dateContainer {
        padding-left: 20px;
        input {
          width: 90%;
        }
      }

      .bottomBtnGroup {
        .fixedWrapper {
          width: 94.7vw;
        }
      }
    }
  }
}
@media (min-width: 520px) and (max-width: 600px) {
  body {
    .userDetailCard {
      .textLabel {
        label {
          padding-left: 20px;
          font-size: 12px;
        }
      }

      .inputContainer {
        padding-left: 20px;
      }
      .dateContainer {
        padding-left: 20px;
        input {
          width: 90%;
        }
      }
      .bottomBtnGroup {
        .fixedWrapper {
          width: 95.1vw;
        }
      }
    }
  }
}

@media (min-width: 0) and (max-width: 599px) {
  body {
    .userDetailCard {
      height: 110%;

      margin: 65px 0px 15px 0px;
      position: relative;
      z-index: 999;

      input {
        width: 90%;
      }
      h2 {
        font-size: 14px;
      }
      h1 {
        padding-left: 25px;
        padding-top: 13px;
      }

      .userInput {
        padding: 13px 8px 0 8px;
      }
      .avatar {
        width: 96px;
        height: 96px;
        border-radius: 50%;
      }
      .picContainer {
        width: 96px;
        width: 96px;
        .changeProfilePic {
          margin-top: 48px;
          height: 48px;
          width: 96px;
          border-radius: 0 0 70px 70px;
          color: $color_white;
          background: #000000;
          opacity: 0.75;
          position: absolute;
          text-align: center;
          p {
            color: $color_white;
            line-height: 16px;
            width: 80%;
            padding: 5% 10% 0 10%;
          }
        }
        img {
          opacity: 1;
        }
      }
      .userImageContainer {
        .circle {
          width: 96px;
          height: 96px;
          background: #ffffff;
          border: 1px solid #aaaaaa;
          box-sizing: border-box;
          display: flex;
          cursor: pointer;
          border-radius: 50%;
          color: $color_purple;
          justify-content: center;
          align-items: center;
        }
      }
      .iconButton {
        padding: 0px;
        margin: 0px;
      }
      .closeIcon {
        width: 30px;
      }
      .edit {
        text-transform: uppercase;
        padding: 25px 25px 0 0;

        p {
          color: $color_purple;
          font-size: 16px;
          line-height: 18px;
        }
      }
      .memberDetail {
        padding: 5px 0 5px 0;
        color: $color_4black;
      }
      .userInfoContainer {
        padding-top: 0px;
      }

      .bottomBtnGroup {
        position: relative;
        text-align: center;
        margin: 0 13px 0 13px;
        width: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: $color_white;
        .fixedWrapper {
          position: fixed;

          bottom: 0;

          left: 13px;
          box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.04);
          background-color: $color_white;
          .hrline {
            display: none;
          }
          .updateBtn {
            padding: 10px;
          }
          .blackBtnSpace {
            padding: 40px;
          }
          .datadelete {
            text-transform: uppercase;
            padding: 10px;
            cursor: pointer;
            color: $color_darkred;
            background-color: $color_white;
          }
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 599px) {
  body {
    .userInitialDetails {
      touch-action: manipulation;
      @include card;
      margin: 72px 13px 17px 13px;

      .slider {
        padding: 0px 5px 4px 20px;
        .customSlider {
          padding: 28px 32px 10px 10px;
          display: block;
        }
      }
      h2 {
        padding: 24px 18px 0px 20px;
      }

      .submitBtn {
        padding-top: 100px;
        justify-content: center;
        display: flex;
      }

      .userName {
        padding: 32px 34px 34px 18px;
      }

      .userGender {
        padding: 0 0 47px 20px;
        .radioGrp {
          .radioButtonForm {
            margin-right: 20px;
          }
          .radioButtonFormClicked {
            margin-right: 20px;
          }
        }
      }
    }
    .userScoreCard {
      touch-action: manipulation;
      margin: 70px 0px 15px 0px;
      .userScoreHeader {
        .userType {
          padding: 18px 0 0 21px;
        }
      }
      .userScoreTable {
        .coachMarker {
          width: 200px;
          padding: 100px 0px 100px 0px;
        }
      }

      .commonCard {
        box-shadow: "none";
        height: 50vh;
        background-color: red;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  body {
    .userDetailCard {
      input {
        width: 95%;
        padding-right: 30px;
      }
      .textLabel {
        label {
          padding-left: 15px;
        }
      }
    }

    .userInitialDetails {
      position: relative;
      z-index: 1000;
      .slider {
        padding: 0px 34px 4px 28px;
        .customSlider {
          padding: 28px 5px 10px 20px;
          display: block;
        }
      }

      .submitBtn {
        padding-top: 100px;
        justify-content: center;
        display: flex;
      }
    }
  }
}
