@mixin bar($left) {
  position: absolute;
  left: $left;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #e2e6ff;
}

@mixin percent($percent) {
  background-color: #61bce1;
  height: 11px;
  width: $percent;
  border-radius: 15px 0 0 15px;
}

@mixin progress($divisor, $length) {
  width: $length;
  border-radius: 15px;
  .bar {
    position: relative;
    background-color: white;
    border-radius: 15px;
    @for $i from 0 to $divisor {
      .percent#{$i*10} {
        @include percent(($length / $divisor) * $i);
      }
    }
    @for $i from 1 to $divisor {
      .ref#{$i*10} {
        @include bar(($length / $divisor) * $i);
      }
    }
  }
}

body {
  .progressbar {
    @for $i from 2 to 20 {
      .progress#{$i} {
        @include progress($i, 290px);
      }
    }
  }
}

@media (min-width: 660px) and (max-width: 900px) {
  body {
    .progressbar {
      @for $i from 2 to 20 {
        .progress#{$i} {
          @include progress($i, 52vw);
        }
      }
    }
  }
}


@media (min-width: 500px) and (max-width: 660px) {
  body {
    .progressbar {
      @for $i from 2 to 20 {
        .progress#{$i} {
          @include progress($i, 52vw);
        }
      }
    }
  }
}

@media (min-width: 370px) and (max-width: 499px) {
  body {
    .progressbar {
      @for $i from 2 to 20 {
        .progress#{$i} {
          @include progress($i, 50vw);
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 370px) {
  body {
    .progressbar {
      @for $i from 2 to 20 {
        .progress#{$i} {
          @include progress($i, 40vw);
        }
      }
    }
  }
}
